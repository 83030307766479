
import React, { Component } from 'react';
import {decryptValue} from '../utils'
import {hideOperacion} from '../redux/actions';
import { connect } from 'react-redux';
import M from 'materialize-css';


class ModalFchNac extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fechaNacimiento: '',
            errorInput: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const inputDate = event.target.value;
        const formattedDate = this.formatDate(inputDate);
        this.setState({ fechaNacimiento: formattedDate, errorInput:false });
    }

    formatDate(inputDate) {
        // Eliminar cualquier caracter que no sea un número
        const onlyNumbers = inputDate.replace(/[^0-9\-]/g, '');

        // Agregar guiones en la posición adecuada para la máscara AAAA-MM-DD
        const formattedDate = onlyNumbers
            .slice(0, 10)

        return formattedDate;
    }

    componentDidMount() {
        M.Modal.init(document.querySelectorAll('.modalFchNac'), {
            dismissible:false,
            onOpenStart: ()=>{
                this.setState({
                    fechaNacimiento: '',
                    errorInput: false,
                })
            }
        });
    }

    render() {
        return (
            <div id="modalFchNac" className="modal modalFchNac">
                <div className="modal-content">
                    <div className='row'>
                        <div className='col s12 right-align'>
                            <i className='material-icons modal-close cursorPointer' onClick={()=>{
                                this.props.hideOperacion();
                            }}>close</i>
                        </div>
                        <div className='col s12 center-align'>
                            <p className='ft-24'>Introduce tu contraseña</p>
                            <p className='ft-16'>
                                Ingresa tu contraseña de seguridad para abrir
                                tu estado de cuenta. (Es tu fecha de nacimiento
                                en formato AAAA-MM-DD)
                            </p>

                        </div>
                        <div className='col s12 left-align mt-1'>
                            <label>
                                Contraseña:
                                <input
                                    type="text"
                                    value={this.state.fechaNacimiento}
                                    onChange={this.handleChange}
                                    maxLength="10" // Limitar la longitud del input para evitar que la fecha sea más larga de lo debido
                                    placeholder="AAAA-MM-DD" // Proporcionar un indicador de formato para el usuario
                                />
                            </label>
                            {
                                this.state.errorInput ?
                                <span class="red-text ft-10" >Fecha de nacimiento incorrecta</span>
                                : null
                            }

                        </div>
                        <div className='col s12 left-align mt-1'>
                            <p className='ft-10' style={{ color: '#666666' }}><i className='material-icons left ft-15'>info_outline</i>  Tu estado de cuenta esta protegido con contraseña</p>
                        </div>


                        <div className='col s12 center-align mt-2'>
                            <button className='btn primaryBtn' 
                            onClick={()=>{
                                console.log("Fecha de nacimiento");
                                console.log(decryptValue(this.props.dataUser.FNacimiento));
                                const fcNacUser = decryptValue(this.props.dataUser.FNacimiento);
                                console.log(fcNacUser)

                                if(this.state.fechaNacimiento.trim() === fcNacUser){
                                    
                                    if(this.props.infoModalData.cb){
                                        this.props.infoModalData.cb(this.state.fechaNacimiento.trim());
                                        M.Modal.getInstance(document.getElementById('modalFchNac')).close();
                                    }
                                }else{
                                    this.setState({errorInput:true});
                                }

                               
                            }}>
                                Confirmar Operación
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    let { infoModalData,dataUser} = false;

    if (state.commonsActions) {
        infoModalData = state.commonsActions.infoModalData || false;
    }

    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
    }


    return { infoModalData,dataUser };
};


export default connect(mapStateToProps, {hideOperacion})(ModalFchNac);