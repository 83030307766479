import React, { Component } from 'react';
import { makeCall, encryptValues, decryptValue, getData_menuExterior } from '../../utils';
import { SERVICES, MESES, TIPOS_DOCS } from '../../properties';
import { connect } from 'react-redux';
import { okButton, setOperacion, showModalFchNac } from '../../redux/actions';
import moment from 'moment';
import Reload from '../reload';
import M from 'materialize-css';
import firebase from 'firebase';


class Documentos extends Component {

    state = {
        tipoDoc: [
            {
                id: 1,
                desc: "Constancia fiscal"
            },
            {
                id: 2,
                desc: "Estado de cuenta"
            }
        ],
        tipoDocS: "",
        yearsList: [],
        yearSelected: "",
        listadoDocs: [],
        listadoEdoCuenta: [],
        docSelected: false,
        mesSelected: "",
        loading: false,
        loadingDoc: false,
        linkSource: false,
        dataMenuExterior: false
    }

    componentDidMount() {
        this.yearsList();
        M.FormSelect.init(document.querySelectorAll('select'), {});
        this.getDateMenuExterior();
    }

    getDateMenuExterior = async() => {
        const dataMenuE = await getData_menuExterior();


        console.log(dataMenuE);

        this.setState({dataMenuExterior:dataMenuE});
    }

    yearsList = () => {
        let currentYear = moment().year();
        let yearsList = [currentYear];

        for (let i = 10; i > 0; i--) {
            currentYear--;
            yearsList.push(currentYear);
        }

        this.setState({ yearsList });
    }



    render() {
        let { tipoDocS, tipoDoc, listadoDocs, docSelected, listadoEdoCuenta, loadingDoc, linkSource } = this.state;
        let colS = `col s12`;

        if (listadoDocs.length) {
            colS = `col s6`;
        }

        if (listadoEdoCuenta.length) {
            colS = `col s6`;
        }

        if (docSelected) {
            colS = `col s4`;
        }
        return (
            <div className="row">
                <div className={`${colS}`}>
                    <div className="row">
                        <div className="col s12 mt-1 mb-1">
                            1.- Selecciona el tipo de documento
                        </div>
                        <div className=" col s12">
                            <label>Tipo</label>
                            <select className="browser-default"
                            disabled={loadingDoc}
                             onChange={(event) => {

                                if (event.target.value === "1" && document.getElementById('yearSelect')) {
                                    let instance = M.FormSelect.getInstance(document.getElementById('yearSelect'));
                                    if (instance) {
                                        instance.destroy();
                                    }
                                } else {
                                    firebase.analytics().logEvent('principal_edo_cta');
                                }

                                this.setState({
                                    tipoDocS: event.target.value,
                                    listadoEdoCuenta: [],
                                    listadoDocs: [],
                                    yearSelected: "",
                                    docSelected: false,
                                    mesSelected: "",
                                    loading: false,
                                    loadingDoc: false,
                                    linkSource: false
                                }, () => {


                                    this.props.blockReturn(false);
                                    M.FormSelect.init(document.querySelectorAll('select'), {});


                                });
                            }} value={tipoDocS}

                                onClick={() => {
                                    firebase.analytics().logEvent('Edocta_tipo');
                                }}
                            >
                                <option value="" >Selecciona una opción...</option>
                                {
                                    tipoDoc.map((v, i) => {
                                        return (
                                            <option value={v.id} key={i}>{v.desc}</option>
                                        )
                                    })
                                }
                            </select>

                        </div>
                        {
                            this.getContent()
                        }
                    </div>
                </div>
                {
                    listadoDocs.length ?
                        <div className={`${colS} borderGray`}>
                            <div className="row mt-1 heightContent ">
                                <div className="col s12 mb-1">
                                    2.- Selecciona el documento.
                                </div>
                                {
                                    listadoDocs.map((v, i) => {
                                        return (
                                            <div className={`col s12 borderBottomDocs ${i === 0 ? 'borderTopDocs' : ""}`}
                                                key={i}>
                                                <div className="row mb-0px cursorPointer hoverContent"
                                                    onClick={() => {
                                                        if (!loadingDoc) {
                                                            this.setState({ docSelected: v }, () => {
                                                                M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <div className="col s10 left-align">
                                                        <p className="mb-0px boldText">{v.Descripcion}</p>
                                                        <p className="noMTop optOperacionTxt">Clave de pago: {v.Producto}</p>
                                                    </div>
                                                    <div className="col s2 center-align">
                                                        <p><i className="material-icons cursorPointer mt-m5">chevron_right</i></p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        : null
                }


                {
                    listadoEdoCuenta.length ?
                        <div className={`${colS} borderGray`}>
                            <div className="row mt-1 heightContent ">
                                <div className="col s12 mb-1">
                                    2.- Selecciona el documento.
                                </div>
                                {
                                    listadoEdoCuenta.map((v, i) => {
                                        return (
                                            <div className={`col s12 hoverContent borderBottomDocs  ${i === 0 ? 'borderTopDocs' : ""}`}
                                                key={i}>
                                                <div className="row mb-0px cursorPointer"
                                                    onClick={() => {
                                                        if (!loadingDoc) {
                                                            this.setState({ loadingDoc: true }, () => {
                                                                this.props.blockReturn(true);
                                                                this.downloadPDF(v.nombre, v.tipo);
                                                            });
                                                        }

                                                    }}
                                                >
                                                    <div className="col s10 left-align">
                                                        <p className="mb-0px">{v.tipo}</p>
                                                        <p className="noMTop">{v.nombre}.{v.tipo}</p>
                                                    </div>
                                                    <div className="col s2 center-align">
                                                        <p><i className="material-icons cursorPointer mt-m5">chevron_right</i></p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    loadingDoc ?
                                        <div className="col s12 mt-1 center-align">
                                            <Reload action={() => { }} />
                                        </div>
                                        : null
                                }
                                {
                                    linkSource && !loadingDoc ?
                                        <div className="col s12 mt-1 center-align cursorPointer brandTextPrimary"
                                            onClick={() => {

                                                if(this.state.dataMenuExterior && this.state.dataMenuExterior.documentos
                                                    && this.state.dataMenuExterior.documentos.show){
                                                       
                                                        this.props.setOperacion({ opt: "pdfViewer", title: "Estado de cuenta", data: linkSource, withPass:true })
                                                    }else{
                                                        this.props.setOperacion({ opt: "pdfViewer", title: "Estado de cuenta", data: linkSource })
                                                    }
                                              
                                               
                                            }}
                                        >
                                            Visualizar Archivo
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        : null
                }

                {
                    docSelected ?
                        <div className={`${colS} borderGray`}>
                            <div className="row mt-1 heightContent ">
                                <div className="col s12 mb-1">
                                    3.- Selecciona el formato.
                                </div>
                                <div className={`col s12 borderBottomDocs borderTopDocs hoverContent`}>
                                    <div className="row mb-0px cursorPointer "
                                        onClick={() => {
                                            if (!loadingDoc) {
                                                this.setState({ loadingDoc: true }, () => {
                                                    this.props.blockReturn(true);
                                                    this.downloadPDF(`${decryptValue(docSelected.Documento)}`, TIPOS_DOCS.PDF);
                                                });
                                            }
                                        }}
                                    >
                                        <div className="col s10 left-align">
                                            <p className="mb-0px">PDF</p>
                                            <p className="noMTop truncate tooltipped"
                                                data-position="bottom" data-tooltip={`${decryptValue(docSelected.Documento)}.pdf`}
                                            >{decryptValue(docSelected.Documento)}.pdf</p>
                                        </div>
                                        <div className="col s2 center-align">
                                            <p><i className="material-icons cursorPointer mt-m5">chevron_right</i></p>
                                        </div>
                                    </div>
                                </div>

                                <div className={`col s12 borderBottomDocs hoverContent`}>
                                    <div className="row mb-0px cursorPointer"
                                        onClick={() => {
                                            if (!loadingDoc) {
                                                this.downloadPDF(`${decryptValue(docSelected.Documento)}`, TIPOS_DOCS.XML);
                                            }
                                        }}
                                    >
                                        <div className="col s10 left-align">
                                            <p className="mb-0px">XML</p>
                                            <p className="noMTop truncate tooltipped"
                                                data-position="bottom" data-tooltip={`${decryptValue(docSelected.Documento)}.xml`}
                                            >{decryptValue(docSelected.Documento)}.xml</p>
                                        </div>
                                        <div className="col s2 center-align">
                                            <p><i className="material-icons cursorPointer mt-m5">chevron_right</i></p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    loadingDoc ?
                                        <div className="col s12 mt-1 center-align">
                                            <Reload action={() => { }} />
                                        </div>
                                        : null
                                }
                                {
                                    linkSource && !loadingDoc ?
                                        <div className="col s12 mt-1 center-align cursorPointer brandTextPrimary"
                                            onClick={() => {

                                                if(this.state.dataMenuExterior && this.state.dataMenuExterior.documentos
                                                    && this.state.dataMenuExterior.documentos.show){

                                                        this.props.setOperacion({ opt: "pdfViewer", title: linkSource.nameDoc, data: linkSource, withPass: true })
                                                    }else{
                                                        this.props.setOperacion({ opt: "pdfViewer", title: linkSource.nameDoc, data: linkSource })
                                                    }
                                               
                                              
                                            }}

                                        >
                                            Visualizar Archivo
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        : null
                }

                <div className="col s12">
                    Los documentos XML están disponibles únicamente si el periodo a consultar generó retención.
                </div>

            </div>
        )
    }

    downloadPDF = async (nameDoc, type) => {
        try {

            let resultDoc = await this.getEdo(nameDoc, type);

            if (resultDoc && resultDoc.response.Exito === 200) {
                const downloadLink = document.createElement("a");
                const fileName = `${nameDoc}.${type.toLowerCase()}`;
                let linkSource = `data:application/pdf;base64,${resultDoc.response.Documento}`;
                if (type === TIPOS_DOCS.XML) {
                    linkSource = `data:application/xml;base64,${resultDoc.response.Documento}`;
                    downloadLink.setAttribute("target", "_blank");
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                } else {
                    this.setState({ loadingDoc: false, linkSource: { link: linkSource, nameDoc } },()=>{
                        this.props.blockReturn(false);
                    });
                }
                this.setState({ loadingDoc: false },()=>{
                    this.props.blockReturn(false);
                });
            } else {
                this.setState({ loadingDoc: false }, () => {
                    this.props.blockReturn(false);
                    this.props.okButton({
                        message: "No se localiza el documento que desea consultar, por favor intente más tarde.", title: "Advertencia", txtButton: "Entendido",
                        statusCode: resultDoc.response ? resultDoc.response.Exito : 404
                    });
                });
            }



        } catch (error) {
            this.setState({ loadingDoc: false },()=>{
                this.props.blockReturn(false);
            });
        }


    }

    disableSearchButton = () => {
        const { tipoDocS, yearSelected, mesSelected } = this.state;

        if (tipoDocS === "1" && yearSelected.toString().trim() !== "") {
            return false;
        } else if (tipoDocS == "2" && yearSelected.toString().trim() !== "" && mesSelected.toString().trim() !== "") {

            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;
            if (yearSelected.toString().trim() === currentYear.toString().trim()) {
                if (mesSelected.toString().trim() !== "" &&
                    parseInt(mesSelected) > currentMonth ) {
                    return true;
                }
            }
            return false;
        }

        return true;
    }

    getContent = () => {
        let { tipoDocS, yearsList, yearSelected, mesSelected, loading, loadingDoc } = this.state;

        if (tipoDocS.trim() !== "") {
            let toShow = "";
            switch (tipoDocS) {
                case "1":
                    toShow =
                        <div className="col s12">
                            <div className="row">
                                <div className=" col s12">
                                    <label>Año</label>
                                    <select className="browser-default"
                                    disabled={loadingDoc}
                                    onChange={(event) => {
                                        this.setState({ yearSelected: event.target.value, listadoDocs: [], docSelected: false, loadingDoc: false },()=>{
                                            this.props.blockReturn(false);
                                        });
                                    }} value={yearSelected} onClick={() => {
                                        firebase.analytics().logEvent('Edocta_const_anio');
                                    }}>
                                        <option value="" >Selecciona una opción...</option>
                                        {
                                            yearsList.map((v, i) => {
                                                return (
                                                    <option value={v} key={i}>{v}</option>
                                                )
                                            })
                                        }
                                    </select>

                                </div>
                                <div className="col s12 center-align">
                                    {
                                        loading ?
                                            <Reload action={() => { }} />
                                            :

                                            <button className="waves-effect btn-flat flatPrimary mt-1 mb-1 w-50"
                                                disabled={this.disableSearchButton() || loadingDoc}
                                                onClick={() => {
                                                    if (!loadingDoc) {
                                                        this.buscarConstancias()
                                                    }

                                                }}
                                            >Buscar</button>
                                    }

                                </div>
                            </div>
                        </div>
                    break;
                case "2":
                    toShow = <div className="col s12">
                        <div className="row">
                            <div className="col s6">
                                <label>Mes</label>
                                <select className="browser-default" id="mesSelect"
                                disabled={loadingDoc}
                                 onChange={(event) => {
                                    this.setState({ mesSelected: event.target.value, linkSource: false });
                                }} value={mesSelected}
                                    onClick={() => {
                                        firebase.analytics().logEvent('Edocta_mes');
                                    }}
                                >
                                    <option value="" >Selecciona un mes...</option>
                                    {
                                        MESES.map((v, i) => {
                                            return (
                                                <option value={v.val} key={i}>{v.desc}</option>
                                            )
                                        })
                                    }
                                </select>

                            </div>
                            <div className=" col s6">
                                <label>Año</label>
                                <select className="browser-default" id="yearSelect" 
                                disabled={loadingDoc}
                                onChange={(event) => {
                                    this.setState({ yearSelected: event.target.value, linkSource: false });
                                }} value={yearSelected} onClick={() => {
                                    firebase.analytics().logEvent('edocta_anio');
                                }}>
                                    <option value="" >Selecciona una opción...</option>
                                    {
                                        yearsList.map((v, i) => {
                                            return (
                                                <option value={v} key={i}>{v}</option>
                                            )
                                        })
                                    }
                                </select>

                            </div>
                            <div className="col s12 center-align">
                                {
                                    loading ?
                                        <Reload action={() => { }} />
                                        :

                                        <button className="waves-effect btn-flat flatPrimary mt-1 mb-1 w-50"
                                            disabled={this.disableSearchButton() || loadingDoc}
                                            onClick={() => {
                                                if (!loadingDoc) {
                                                    this.buscarEstado()
                                                }
                                            }}
                                        >Buscar</button>
                                }
                            </div>
                            {
                                !loadingDoc ?
                                    <div className="col s12 center-align mt-3">
                                        <span className="cursorPointer brandTextPrimary"

                                            onClick={() => {
                                                this.props.setOperacion({ opt: "historicoDocs", title: "Histórico estado de cuenta" })
                                            }}
                                        >Revisar histórico</span>
                                    </div>
                                    : null
                            }

                        </div>
                    </div>
                    break;
                default:
                    toShow = "";
                    break;
            }
            return toShow;
        }

        return "";
    }

    buscarConstancias = async () => {
        try {
            this.setState({ loading: true, linkSource: false });

            let bodyConstancias = SERVICES.CONSTANCIAS.body;
            bodyConstancias.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
            bodyConstancias.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
            bodyConstancias.Anio = this.state.yearSelected;

            let resultConstancia = await makeCall(SERVICES.CONSTANCIAS, bodyConstancias);

            if (resultConstancia && resultConstancia.response) {
                if (resultConstancia.response.Exito === 200 && resultConstancia.response.TTCONSTANCIAS && resultConstancia.response.TTCONSTANCIAS['TT-CONSTANCIAS']) {
                    this.setState({ listadoDocs: resultConstancia.response.TTCONSTANCIAS['TT-CONSTANCIAS'] });
                } else {
                    this.props.okButton({
                        message: "No se localiza el documento que desea consultar, por favor intente más tarde.", title: "Advertencia", txtButton: "Entendido",
                        statusCode: resultConstancia.response.Exito
                    });
                    // M.toast({ html: "No se localiza el documento que desea consultar, por favor intente más tarde. 1", classes: "red darken-3" });
                }
            } else {
                this.props.okButton({
                    message: "No se localiza el documento que desea consultar, por favor intente más tarde.", title: "Advertencia", txtButton: "Entendido",
                    statusCode: 404
                });
                // M.toast({ html: "No se localiza el documento que desea consultar, por favor intente más tarde.", classes: "red darken-3" });
            }
            this.setState({ loading: false });
        } catch (error) {
            this.props.okButton({
                message: "No se localiza el documento que desea consultar, por favor intente más tarde.", title: "Advertencia", txtButton: "Entendido",
                statusCode: 404
            });
            // M.toast({ html: "No se localiza el documento que desea consultar, por favor intente más tarde.", classes: "red darken-3" });
            this.setState({ loading: false });
        }
    }

    buscarEstado = async () => {
        try {
            this.setState({ loading: true, linkSource: false });
            let { yearSelected, mesSelected } = this.state;
            let contrato = this.props.currentContrato &&
                this.props.currentContrato.Contrato ? this.props.currentContrato.Contrato : "";
            let nameDoc = `${contrato}_${yearSelected}${mesSelected}`;

            this.setState({
                loading: false,
                listadoEdoCuenta: [
                    {
                        nombre: `${nameDoc}`,
                        tipo: TIPOS_DOCS.PDF
                    },
                    {
                        nombre: `${nameDoc}`,
                        tipo: TIPOS_DOCS.XML
                    }
                ]
            });

            // let resultEstado = await this.getEdo(nameDoc, TIPOS_DOCS.PDF);

            // if (resultEstado && resultEstado.response) {
            //     if (resultEstado.response.Exito === 200) {
            //         this.setState({
            //             listadoEdoCuenta: [
            //                 {
            //                     nombre: `${nameDoc}`,
            //                     tipo: TIPOS_DOCS.PDF
            //                 },
            //                 {
            //                     nombre: `${nameDoc}`,
            //                     tipo: TIPOS_DOCS.XML
            //                 }
            //             ]
            //         });
            //     } else {
            //         this.props.okButton({
            //             message: "No se localiza el documento que desea consultar, por favor intente más tarde.", title: "Advertencia", txtButton: "Entendido",
            //             statusCode: 404
            //         });
            //         // M.toast({ html: "No se logro obtener el estado de cuenta intente de nuevo.", classes: "red darken-3" });
            //     }
            // } else {
            //     this.props.okButton({
            //         message: "No se localiza el documento que desea consultar, por favor intente más tarde.", title: "Advertencia", txtButton: "Entendido",
            //         statusCode: 404
            //     });
            //     // M.toast({ html: "No se logro obtener el estado de cuenta intente de nuevo.", classes: "red darken-3" });
            // }
            // this.setState({ loading: false });
        } catch (error) {
            this.props.okButton({
                message: "No se localiza el documento que desea consultar, por favor intente más tarde.", title: "Advertencia", txtButton: "Entendido",
                statusCode: 500
            });
            // M.toast({ html: "No se logro obtener el estado de cuenta intente de nuevo.", classes: "red darken-3" });
            this.setState({ loading: false });
        }
    }


    getEdo = async (nameDoc, type) => {
        try {
            let { yearSelected, mesSelected, tipoDocS } = this.state;
            let bodyEstado = SERVICES.GET_DOCUMENTO.body;
            bodyEstado.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
            bodyEstado.NombreDoc = encryptValues(nameDoc);
            bodyEstado.TipoDoc = type
            bodyEstado.seccion = 1;
            bodyEstado.Anio = encryptValues(yearSelected);
            bodyEstado.Mes = encryptValues(mesSelected);
            bodyEstado.Tipo = tipoDocS.trim() === "1" ? "C" : "E";
            bodyEstado.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");

            let Documento = "";

            let resultEstado = await makeCall(SERVICES.GET_DOCUMENTO, bodyEstado);

            if (resultEstado && resultEstado.response.Secciones) {
                let seccionI = bodyEstado.seccion;
                Documento += resultEstado.response.Documento;
                while (seccionI < resultEstado.response.Secciones) {
                    seccionI += 1;
                    bodyEstado.seccion = seccionI;
                    let resulEstadoPlus = await makeCall(SERVICES.GET_DOCUMENTO, bodyEstado);
                    if (resulEstadoPlus && resulEstadoPlus.response) {

                        Documento += resulEstadoPlus.response.Documento;
                    }
                }
                resultEstado.response.Documento = decryptValue(Documento);
                console.log("documento");
                console.log(decryptValue(Documento))
            }

            return resultEstado;
        } catch (error) {
            return false;
        }

    }
}

const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
    }

    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history };
};

export default connect(mapStateToProps, { okButton, setOperacion, showModalFchNac })(Documentos);