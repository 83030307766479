
export const FIREBASE_PROPS = {

  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,

  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,

  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,

  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,

  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,

  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,

  appId: process.env.REACT_APP_FIREBASE_APP_ID,

  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

};
export const TXT_SALDO_LIQUIDO = "Saldo líquido total de la cartera en MXP";
export const MINUTOS_SESION =process.env.REACT_APP_MINUTOS_SESION;

export const VAPIDKEY = "BLQ1DxPwUe_TigggpgD_ynFdx1G6yTUTE457V8Eif9Kjcu0oj5Q1q3W8uAvMzwbw1TV5d1_lOnMXu7-lTRUpDTk";

export const ID_CUENTAS_REGISTRADAS = "cuentas_registradas";

export const BASE_URL = "https://us-central1-valmex-cb-qa.cloudfunctions.net/";

export const MENUS = {
  USUARIO: "configuraciones/menus/usuario",
  PRINCIPAL: "configuraciones/menus/principal",
  TIPS_IMAGENES_TEMPORADA: "configuraciones/tips_seguridad"
}

export const TEXTOS = {
  BLOQUEO: {
    title: "Cuenta Bloqueada",
    message: "Por seguridad su cuenta ha sido bloqueada, favor de realizar el proceso de desbloqueo a través de la app movil o el sitio web.",
    txtButton: "Desbloquear"
  },
  CUENTA_ERRONEA: {
    title: "Iniciar Sesión",
    message: "El usuario y/o contraseña especificado no son correctos favor de verificar."
  },
  PASS_ACTUALIZADO: {
    title: "Contraseña",
    message: "La contraseña se ha actualizado con éxito.",
    txtButton: "Entrar"
  },
  NO_PERMITE_COMPRA_VENTA:{
    title:"Advertencia",
    message: "Es necesario contar con el servicio de ejecución de operaciones. Contacte a su Asesor.",
    txtButton: "OK"
  },
  DIA_INHABIL:{
    title:"Atención",
    message:"No es posible registrar operaciones en días inhábiles.",
    txtButton: "Entendido",
    statusCode: 404
  },
  CONTRATO_SOLO_CONSULTA: "El contrato es solo consulta, para realizar operaciones favor de contactar a su promotor."

}

/** URL SERVICES */;
export const SERVICES = JSON.parse(process.env.REACT_APP_SERVICES);

export const MESES = [
  { desc: "Enero", val: "1", mv: 1 },
  { desc: "Febrero", val: "2", mv: 2 },
  { desc: "Marzo", val: "3", mv: 3 },
  { desc: "Abril", val: "4", mv: 4 },
  { desc: "Mayo", val: "5", mv: 5 },
  { desc: "Junio", val: "6", mv: 6 },
  { desc: "Julio", val: "7", mv: 7 },
  { desc: "Agosto", val: "8", mv: 8 },
  { desc: "Septiembre", val: "9", mv: 9 },
  { desc: "Octubre", val: "10", mv: 10 },
  { desc: "Noviembre", val: "11", mv: 11 },
  { desc: "Diciembre", val: "12", mv: 12 },
]

/*Plugins CHARTS*/
export const PIE_PLUGIN = {
  legend: {
    display: false
  },
  plugins: {
    labels: {
      render: function( data){

        if(data.value > 0){
          return `${data.value}%`;
        }else{
          return "0%";
        }
      },
      fontColor: '#ffffff',
      precision: 2,
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
      
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        var total = meta.total;
        var currentValue = dataset.data[tooltipItem.index];
        var percentage = parseFloat((currentValue / total * 100).toFixed(2));

        if(currentValue > 0){
        return ' ' + currentValue + '%';
        }else{
          return '0%';
        }
      },
      title: function (tooltipItem, data) {
        return data.labels[tooltipItem[0].index];
      }
    }
  },
}

export const PATHS = JSON.parse(process.env.REACT_APP_PATHS);

export const ACCION_CODIGO = {
  ACTIVACION: "A",
  RECUPERACION: "C",
  ACCESO: "A",
  ROBO: "R",
  ACTUALIZA_PASS: "P",
  ACCESO_WEB: "X",
  ACTIVACION_WEB: "W",
  RECUPERA_ACCION_WEB: "Y"
}

export const TIPOS_DOCS = {
  XML: "XML",
  PDF: "PDF"
}

export const TIPOS_CHEQUERAS = {
  RETIRO: "R",
  DEPOSITO: "D",
  INFORMATIVA: "G"
}

export const TIPO_OPERACION = {
  DEPOSITO: "D",
  RETIRO: "R"
}

export const OPERACION_EMISORA_FONDO = {
  COMPRA: "C",
  VENTA: "V"
}

export const DIVISA_SALDO_LIQUIDO = 'MXP';

export const LIST_TO_VALUES = [
  'Contrato',
  'FechaAlta',
  'NombreProm',
  'MailProm',
  'TelProm',
  'Nombre',
  'Cuenta',
  'CLABE',
  'NombreDoc',
  'Celular',
  'ApPaterno',
  'ApMaterno',
  'TelCat',
  'FOper',
  'Fliq',
  'FLiq',
  'Folio',
  'Emisora',
  'Serie',
  'Titulos',
  'Importe',
  'Fecha',
  'Hora',
  'TipoOper',
  'CodigoValido',
  'SoftTokenValido',
  'AceptoGeoWEB'
]

export const LIST_SERVICES_DECRYPT =[
    'clientes/chequeras',
    `clientes/contratos`,
    'clientes/tipoServicio',
    'opera/constancias',
    'opera/rendimientos',
    'opera/movimientos',
    'opera/posicion',
    'opera/saldoLiquido',
    'seguridad/validaIdentidad',
    'seguridad/validaUsuario',
    'seguridad/validaToken',
    'seguridad/validaCodigo',
    'opera/operaEfectivo',
    'opera/operaFondos'
  ]


  export const LIST_SERVICES_NOS =[
    'seguridad/guardaDatos',
    'seguridad/solicitaCodigo',
    'seguridad/validaCodigo',
    'seguridad/validaIdentidad',
    'seguridad/validaToken'
  ]
